@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@100;400;600&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  font-size: 16px;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
*:focus {
  box-shadow: none !important;
}
#phaser-container canvas {
  display: block;
}
.remote-users {
  display: flex;
  width: 100%;
  padding: 0.5rem 0;
  flex-direction: row;
  position: absolute;
  align-items: center;
  justify-content: center;
  top: 0.5rem;
  gap: 1rem;
  overflow-x: auto;
}
.local-user-media {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
}
.local-user-controls {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
}
.video-renderer {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}
video {
  border-radius: 0.5rem;
}
.full-screen-video {
  min-height: 0;
  min-width: 0;
  flex: 1;
}
.button-grid {
  width: 160px;
  display: flex;
  justify-content: space-evenly;
  position: absolute;
  top: 5px;
  right: 10px;
}
/* *:hover::-webkit-scrollbar-thumb {
  background: #eee;
}
*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
*::-webkit-scrollbar-track {
  background: rgba(51, 51, 51, 0.2);
  border-radius: 3px;
}
*::-webkit-scrollbar-corner {
  background: rgba(51, 51, 51, 0.2);
}
*::-webkit-scrollbar-thumb {
  transition: rgba(51, 51, 51, 0.2) 0.1s;
  background: rgba(238, 238, 238, 0.8);
  border-radius: 3px;
} */

::-webkit-scrollbar {
  display: none;
}

/* Autofill */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px rgba(44, 44, 49, 0.9) inset !important;
  -webkit-box-shadow: 0 0 0 30px rgba(44, 44, 49, 0.9) inset !important;
  -webkit-text-fill-color: #ffffff !important;
}
